import React from 'react'

import renaudbaton from '../assets/self/renaud_baton.jpg'


const Explain = () => {
  return (
    <section className='my-12'>
    <div className="container max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-white ">
        <div className="flex flex-wrap items-center -mx-8">
            <div className="w-full lg:w-1/2 px-8">
                <div className="mb-12 lg:mb-0 pb-12 lg:pb-0 border-b lg:border-b-0">
                          <img src={renaudbaton} alt="Baton" className='rounded-lg shadow-lg'/>
                </div>
            </div>
            <div className="w-full lg:w-1/2 px-8">
                <ul className="space-y-12">
                    <li className="flex -mx-4">
                        <div className="px-4">
                            <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-red-100 text-primary">
                                1
                            </span>
                        </div>
                        <div className="px-4">
                            <h3 className="my-4 text-xl font-semibold ">
                                Tous les Mercredis 19h30 à 21h
                            </h3>
                        </div>
                    </li>
                    <li className="flex -mx-4">
                        <div className="px-4">
                            <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-red-100 text-primary">
                                2
                            </span>
                        </div>
                        <div className="px-4">
                            <h3 className="my-4 text-xl font-semibold ">
                                Venez apprendre à vous défendre dans le plus grand dojo d'europe
                            </h3>
                           
                        </div>
                    </li>
                    <li className="flex -mx-4">
                        <div className="px-4">
                            <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-red-100 text-primary">
                                3
                            </span>
                        </div>
                        <div className="px-4">
                            <h3 className="my-4 text-xl font-semibold ">
                                Techniques d'auto-défense en situation réelles
                            </h3>
                           
                        </div>
                          </li>
                          <li className="flex -mx-4">
                        <div className="px-4">
                            <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-red-100 text-primary">
                                4
                            </span>
                        </div>
                        <div className="px-4">
                            <h3 className="my-4 text-xl font-semibold ">
                                Développer une boîte à outils de techniques réalistes et utiles
                            </h3>
                            
                        </div>
                          </li>
                          <li className="flex -mx-4">
                        <div className="px-4">
                            <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-red-100 text-primary">
                                5
                            </span>
                        </div>
                        <div className="px-4">
                            <h3 className="my-4 text-xl font-semibold ">
                                                                      Avoir les clés pour réagir en situation de stress

                            </h3>
                           
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default Explain