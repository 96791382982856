import './App.css';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import InformationBanner from './components/InformationBanner';
import Squad from './components/Squad';
import Pub from './components/shared/Pub';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Routes, Route } from 'react-router-dom';

import Cours from './components/Cours';

import pub1 from './assets/para/img4.jpg';
import pub2 from './assets/para/img1.jpg';
import pub3 from './assets/para/self1.jpg';
import pub4 from './assets/para/9.jpg';
import pub5 from './assets/para/10.jpg';

import Contact from './components/Contact';
import GallerieK from './components/GallerieK';
import Sponsors from './components/Sponsors';
import Video from './components/Video';
import Self from './components/Self';
import Explain from './components/Explain';
import Ouvert from './components/Ouvert';
import GallerieS from './components/GallerieS';
import Price from './components/Price';
import CallToActions from './components/CallToActions';
import Footer from './components/Footer';
import Title from './components/shared/Title';
// A mettre quand il y a une annonce a faire 
// import InformationBanner from './components/InformationBanner';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="overflow-hidden leading-normal tracking-normal gradient ">
            <NavBar />
            <ParallaxProvider>
              <Hero />
              {/* A mettre quand il y a une annonce a faire  */}
              <InformationBanner />
              <Squad />
              <Pub img={pub1} />
              <Cours />
              <Pub img={pub2} />
              <Contact />
              <GallerieK />
              <Title before="" black="Se" title="lf-Défense" desc="" />
              <Video />
              <Self />
              <Pub img={pub3} />
              <Explain />
              <Pub img={pub4} />
              <Ouvert />
              <Pub img={pub5} />
              <GallerieS />
              <Sponsors />
              <Price />
              <CallToActions />
              <Footer />
            </ParallaxProvider>
          </div>
        }
      />
    </Routes>
  );
}

export default App;
