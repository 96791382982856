import React from 'react';
import k3 from '../assets/karate/gallery/3.jpg';
import k4 from '../assets/karate/gallery/4.jpg';
import k5 from '../assets/karate/gallery/5.jpg';
import k6 from '../assets/karate/gallery/guillaume-nico.jpg';
import k8 from '../assets/karate/gallery/8.jpg';
import k9 from '../assets/squad/guillaume.jpg';
import Title from './shared/Title';

const GallerieK = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <Title before="" black="Ka" title="raté" desc="Gallerie" />

        <div className="flex flex-wrap">
          <div className="flex flex-wrap -m-1 md:-m-2">
            <div className="flex flex-wrap w-1/2">
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full"
                  src={k6}
                />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full"
                  src={k4}
                />
              </div>
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full"
                  src={k3}
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/2">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full"
                  src={k5}
                />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full"
                  src={k8}
                />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full"
                  src={k9}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerieK;
