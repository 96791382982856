import React from 'react';

const InformationBanner = () => {
  return (
    <section id="error" className="py-8 bg-white">
      <div className="flex items-center justify-center px-2 py-1 m-1 font-medium text-red-100 bg-red-700 border border-red-700 rounded-md">
        {/* <div className="flex-initial max-w-full text-xl font-normal">
          Reprise des cours le 05/09/22 à 20h pour le karaté adultes, le 07/09/22 pour
          le karaté enfants et la self-défense, attention changement d'horaires sur les cours enfants
        </div> */}
        <div className="flex-initial max-w-full text-xl font-normal">
          Rentrée 24-25 le 09 et 11 septembre 2024, rentrée cours enfants mercredi à 18h-18h45-19h15
        </div>
      </div>
    </section>
  );
};

export default InformationBanner;
