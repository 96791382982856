import React from 'react';
import edouard from '../assets/squad/edouard.jpg';
import guillaume from '../assets/squad/guillaume-seiza.jpg';
import nicolas from '../assets/squad/nico-seiza.jpg';
import Teamate from './shared/Teamate';
import Title from './shared/Title';

const Squad = () => {
  return (
    <section id="karate" className="p-10 px-6 mx-auto bg-white">
      <div className="container max-w-5xl m-8 mx-auto">
        <Title before="l'" black="éq" title="uipe" desc="Les enseignants" />
        <div className="flex flex-wrap justify-center">
          <Teamate
            img={edouard}
            name="Edouard Gonnet"
            title="Instructeur fédéral"
            desc="Troisième dan F.F.K.D.A, Edouard s'entraîne
            également à l'Ecole Clermontoise de Karaté, aux Cezeaux et à
            Montauban. Professeur de la section self défense."
          />
          <Teamate
            img={guillaume}
            name="Guillaume Chabrier"
            title="Instructeur fédéral"
            desc="Professeur de la section adulte du club."
          />
          <Teamate
            img={nicolas}
            name="Nicolas Birnbaum"
            title="Instructeur fédéral"
            desc="Professeur de la section adulte et enfant du club."
          />
        </div>
      </div>
    </section>
  );
};

export default Squad;
